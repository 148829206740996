import { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';
import { ButtonLink } from '@landing/ui/components';
import { BreakpointsEnum } from '@landing/types';
import SlideImage from './components/SlideImg';
import { useInterval } from '@landing/hooks/useInterval';
import { useIntersectionObserver } from '@landing/hooks/useIntersectionObserver';
import { useRouter } from 'next/router';
import apx from '@landing/constants/adaptivepx';
import { ParsedUrlQuery } from 'querystring';

interface Props {
  slides: {
    id: number;
    number: number;
    title: string;
    src: string;
  }[];
  buttonText: string;
  buttonLink: string;
  buttonQuery?: ParsedUrlQuery;
  className?: string;
  isMuchText?: boolean;
}

const StepSlider: FC<Props> = ({
  slides,
  buttonText,
  buttonLink,
  buttonQuery,
  className,
  isMuchText = false,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const rootRef = useRef(null);
  const entry = useIntersectionObserver(rootRef, {});
  const router = useRouter();

  const shouldStartInterval = entry?.isIntersecting ? 2000 : null;

  useInterval(() => {
    setActiveSlideIndex((prevValue) => {
      if (prevValue === 2) return 0;

      return prevValue + 1;
    });
  }, shouldStartInterval);

  const activeSlide = slides[activeSlideIndex];

  return (
    <Root ref={rootRef} className={className} $isMuchText={isMuchText}>
      <ContentWrap>
        <ImageWrap>
          {slides.map((slide) => {
            const isActive = activeSlide.id === slide.id;

            return (
              <Fade key={`${slide.id}-${slide.title}`} $isActive={isActive}>
                <SlideImage
                  src={slide.src}
                  alt={`Слайд №${slide.id}: ${slide.title}`}
                />
              </Fade>
            );
          })}
        </ImageWrap>
        <RightContent>
          <Steps>
            {slides.map((slide, index) => {
              return (
                <Step
                  key={slide.title}
                  $isActive={slide.id <= activeSlideIndex}
                  onClick={() => setActiveSlideIndex(index)}
                >
                  <Number>{slide.number}</Number>
                  <p>{slide.title}</p>
                </Step>
              );
            })}
          </Steps>
          <StyledButtonLink isLarge href={buttonLink} query={{...router.query, ...buttonQuery}}>
            {buttonText}
          </StyledButtonLink>
        </RightContent>
      </ContentWrap>
    </Root>
  );
};

export default StepSlider;

const ContentWrap = styled.div`
  display: flex;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    /* flex-wrap: wrap; */
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    flex-direction: column;
  }
`;

const ImageWrap = styled.div`
  width: 100%;

  max-width: ${apx.d(570)};
  height: ${apx.d(478)};
  margin-right: ${apx.d(60)};
  border-radius: ${apx.d(40)};

  background-color: ${COLORS.dark600};
  position: relative;
  overflow: hidden;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    height: ${apx.l(280)};
    margin-right: ${apx.l(30)};
    margin-bottom: ${apx.l(50)};
    border-radius: ${apx.l(20)};
    max-width: ${apx.l(570)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    min-width: ${apx.m(340)};
    max-width: none;
    width: 100% !important;
    mask-right: 0;
    margin-bottom: ${apx.m(36)};
    border-radius: ${apx.m(23)};
    height: ${apx.m(280)};
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Step = styled.div<{ $isActive: boolean }>`
  max-height: ${apx.d(64)};
  display: flex;
  align-items: center;
  ${TYPOGRAPHY.bodyMedium32};
  color: ${COLORS.primary300};
  cursor: pointer;
  position: relative;

  p {
    font-size: ${apx.d(32)};
    line-height: 130%;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(24)} !important;
    line-height: ${apx.l(24)} !important;
    max-height: ${apx.l(64)};

    p {
      font-size: ${apx.l(18)};
      line-height: 130%;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(28)} !important;
    line-height: 130% !important;
    max-height: ${apx.m(38)};

    p {
      font-size: ${apx.m(18)};
      line-height: 130%;
    }
  }

  & > p {
    transition: color 1s ease;
  }

  &:not(:last-of-type) {
    margin-bottom: ${apx.d(58)};

    @media (max-width: ${BreakpointsEnum.tablet}px) {
      margin-bottom: ${apx.l(50)};
    }

    @media (max-width: ${BreakpointsEnum.mb}px) {
      margin-bottom: ${apx.m(34)};
    }
  }

  &:not(:first-of-type):before {
    content: '';
    width: ${apx.d(2)};
    height: ${apx.d(58)};

    bottom: ${apx.d(64)};
    left: ${apx.d(30)};

    background-color: ${({ $isActive }) =>
      $isActive ? COLORS.dark600 : COLORS.primary400};
    position: absolute;

    transition: background-color 1s ease;

    @media (max-width: ${BreakpointsEnum.tablet}px) {
      height: ${apx.l(50)};
      width: ${apx.l(2)};

      bottom: ${apx.l(64)};
      left: ${apx.l(31)};
    }

    @media (max-width: ${BreakpointsEnum.mb}px) {
      height: ${apx.m(34)};
      width: ${apx.m(2)};

      bottom: ${apx.m(38)};
      left: ${apx.m(18)};
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      div {
        background-color: ${COLORS.dark600};
        border-color: ${COLORS.dark600};
        color: ${COLORS.white};
      }

      p {
        color: ${COLORS.dark800};
      }
    `};
`;

const Steps = styled.div`
  margin-bottom: ${apx.d(57)};
  display: flex;
  flex-direction: column;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(40)};

    &:not(:last-of-type) {
      margin-bottom: ${apx.l(54)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(40)};

    &:not(:last-of-type) {
      margin-bottom: ${apx.m(54)};
    }
  }
`;

const Number = styled.div`
  min-width: ${apx.d(64)};
  min-height: ${apx.d(64)};
  margin-right: ${apx.d(37)};

  border: ${apx.d(2)} solid ${COLORS.primary400};

  color: ${COLORS.primary400};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  ${TYPOGRAPHY.heading6Light48};
  font-size: ${apx.d(40)};
  line-height: ${apx.d(40)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    ${TYPOGRAPHY.headline2Regular36};

    font-size: ${apx.l(36)};
    line-height: ${apx.l(36)};

    min-width: ${apx.l(64)};
    min-height: ${apx.l(64)};
    margin-right: ${apx.l(17)};
    border: ${apx.l(2)} solid ${COLORS.primary400};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(28)};
    line-height: ${apx.m(28)};

    min-width: ${apx.m(38)};
    min-height: ${apx.m(38)};
    margin-right: ${apx.m(22)};
    border: ${apx.m(2)} solid ${COLORS.primary400};
  }

  transition: background-color 1s ease, color 1s ease, border-color 1s ease;
`;

const StyledButtonLink = styled(ButtonLink)`
  padding: ${apx.d(20)} ${apx.d(86)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin: 0 auto;
    padding: ${apx.l(20)} ${apx.l(65)};
    ${TYPOGRAPHY.bodySemibold20};
    font-size: ${apx.l(18)} !important;
    line-height: 130% !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin: 0 auto;
    padding: ${apx.m(17)} ${apx.m(40)};
    ${TYPOGRAPHY.bodySemibold20};
    font-size: ${apx.m(20)} !important;
    line-height: 130% !important;
  }
`;

const Root = styled.div<{ $isMuchText: boolean }>`
  width: 100%;
  background: ${COLORS.dark100};
`;

const Fade = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: 100%;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};

  transition: opacity 1s ease;
`;
