import { useMemo, useCallback } from 'react';

export const useLockScroll = () => {
  const lockScroll = useCallback((cb?: () => void) => {
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
    document.body.style.top = `-${window.scrollY}px`;
    cb?.();
    document.body.style.position = 'fixed';
  }, []);

  const unlockScroll = useCallback(() => {
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.height = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }, []);

  return useMemo(
    () => ({
      lockScroll,
      unlockScroll,
    }),
    [lockScroll, unlockScroll],
  );
};
