import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import { FC } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import StepSlider from '../StepSlider';
import { slides } from './data';
import apx from '@landing/constants/adaptivepx';

const HowToGetSection: FC = () => {
  return (
    <Root>
      <StyledContainer>
        <Title>Как попасть</Title>
        <StepSlider
          isMuchText
          slides={slides}
          buttonText="Заполнить анкету"
          buttonLink={API_URL.therapistSignUp}
          buttonQuery={{onlyRegistration: 'true'}}
        />
      </StyledContainer>
    </Root>
  );
};

export default HowToGetSection;

const Root = styled.div`
  width: 100%;
  background: ${COLORS.dark100};
`;

const StyledContainer = styled(Container)`
  padding: ${apx.d(80)} ${apx.d(60)} ${apx.d(80)} ${apx.d(140)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(40)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(40)} ${apx.m(20)} ${apx.m(80)};
  }
`;

const Title = styled.h2`
  margin-bottom: ${apx.d(50)};
  ${TYPOGRAPHY.heading2SemiBold64};
  color: ${COLORS.dark800};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(24)};
    font-size: ${apx.l(40)};
    line-height: ${apx.l(40)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(24)};
    font-size: ${apx.m(36)};
    line-height: ${apx.m(36)};
  }
`;
